import { Button, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react';

import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import type {
  WorkOrderRecentlyUsedValues_AssetFragment,
  WorkOrderRecentlyUsedValues_ProductFragment,
} from './WorkOrderRecentlyUsedValues.generated';

gql`
  fragment WorkOrderRecentlyUsedValues_Product on Product {
    id
    name
  }
  fragment WorkOrderRecentlyUsedValues_Asset on Asset {
    id
    name
  }
`;

type Item = WorkOrderRecentlyUsedValues_ProductFragment | WorkOrderRecentlyUsedValues_AssetFragment;

type Props<I extends Item> = {
  items: I[];
  onClick: (item: I) => void;
};

export const WorkOrderRecentlyUsedValues = <I extends Item>(props: Props<I>) => {
  const { t } = useTranslation();

  if (props.items.length === 0) {
    return (
      <Text display='block' alignSelf='center' fontSize='xs'>
        {t('form.recently-used-no-items')}
      </Text>
    );
  }

  return (
    <Wrap spacingY={0}>
      {props.items.map((item) => (
        <WrapItem key={item.id}>
          <Button variant='unstyled' onClick={() => props.onClick(item)}>
            <Tag>{item.name}</Tag>
          </Button>
        </WrapItem>
      ))}
    </Wrap>
  );
};
