import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { Maybe } from '../common/types';
import WorkOrderForm, { WorkOrderFormProps } from '../components/workOrders/WorkOrderForm';
import { WorkOrderFormDataType } from '../modules/workOrders/hooks/useAddWorkOrder';
import { useScreenInfos } from '../utils/mobiles/useScreenInfos';

type CreateWorkOrderType = {
  createWorkOrderInput: (
    args: CreateWorkOrderInputArgsType
  ) => Promise<Maybe<WorkOrderFormDataType>>;
};

type CreateWorkOrderInputArgsType = Omit<WorkOrderFormProps, 'submit' | 'pause' | 'cancel'> & {
  submit: (workOrder: WorkOrderFormDataType) => Promise<boolean>;
  pause?: (workOrder: WorkOrderFormDataType) => Promise<boolean>;
  cancel?: () => void;
};

const defaultCreateWorkOrderData = {
  createWorkOrderInput: (): Promise<Maybe<WorkOrderFormDataType>> => {
    throw new Error('確認モーダルが実装されていません');
  },
};

const CreateWorkOrderContext = createContext<CreateWorkOrderType>(defaultCreateWorkOrderData);

export const CreateWorkOrderProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<ReactNode>(undefined);

  const { isDesktop } = useScreenInfos();

  const createOpener = useCallback(
    (args: CreateWorkOrderInputArgsType): Promise<Maybe<WorkOrderFormDataType>> =>
      new Promise((resolve) => {
        const { submit, cancel, pause } = args;

        const handleClose = () => {
          setModal(undefined);
          resolve(undefined);
        };

        const handleCancel = () => {
          if (cancel) cancel();
          handleClose();
        };

        const handleSubmit = async (data: WorkOrderFormDataType) => {
          const result = await submit(data);
          if (result) handleClose();
        };

        const handlePause = async (data: WorkOrderFormDataType) => {
          if (!pause) throw new Error('Impossible to pause work order');
          const result = await pause(data);
          if (result) handleClose();
        };

        setModal(
          <Modal
            isOpen={true}
            onClose={handleClose}
            size={{ base: 'full', md: '2xl' }}
            isCentered
            scrollBehavior='inside'
          >
            <ModalOverlay />
            <ModalContent>
              {isDesktop && <ModalCloseButton />}
              <ModalBody
                p={0}
                height={{ base: '100vh', md: '80vh' }}
                data-testid='create-work-order-modal-body'
              >
                <WorkOrderForm
                  {...args}
                  submit={handleSubmit}
                  cancel={handleCancel}
                  pause={handlePause}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        );
      }),
    [isDesktop]
  );

  const createWorkOrderInput = (args: CreateWorkOrderInputArgsType) => createOpener(args);

  return (
    <CreateWorkOrderContext.Provider value={{ createWorkOrderInput }}>
      {children}
      {modal}
    </CreateWorkOrderContext.Provider>
  );
};

// useAddWorkOrderと共通化して、この中でWorkOrderを作成するようにしたい。
// ただ、Requestのフォームがあるので、そのままでは共通化できない。updateRequestでWorkOrderを作るべきではなく、addWorkOrderで作るべき。
export const useCreateWorkOrder = () => useContext(CreateWorkOrderContext);

export type WorkOrderFormDefaultValuesType = WorkOrderFormProps['workOrder'];
