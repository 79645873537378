import GroupSelectInput from '@/common/components/GroupSelectInput';
import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import PageTitle from '@/common/components/PageTitle';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import UserSelectInput from '@/common/components/UserSelectInput';
import { useApplicationContext } from '@/context/ApplicationContext';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import { useTemplateContext } from '@/context/TemplateContext';
import { type WorkOrderPriority, type WorkOrderStatus } from '@/graphql/types';
import { AssetMultiLayerSelect } from '@/modules/assets/components/AssetMultiLayerSelect';
import { ICheckListTemplate, ICreateCheckListInput } from '@/modules/checkList';
import { useHierarchicalProducts } from '@/modules/products';
import { toUserInputs } from '@/modules/users';
import { WorkOrderFormDataType } from '@/modules/workOrders/hooks/useAddWorkOrder';
import {
  IWorkOrderAssigneeInput,
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
  WorkOrderDoneStatus,
} from '@/modules/workOrders/types/workOrder';
import { IWorkOrderCustomField } from '@/modules/workOrders/types/workOrderCustomField';
import { IWorkOrderTemplateBase } from '@/modules/workOrders/types/workOrderTemplate';
import { convertCustomFieldValueToInputValue } from '@/modules/workOrders/utils/customFields';
import {
  CheckListFormValueType,
  generateCheckListInitFormValue,
} from '@/utils/customFields/checkListCustomFields';
import {
  formatDateToYYYYMMDDForInput,
  formatDateToYYYYMMDDHHmmForInput,
  isFromDateAfterToDate,
} from '@/utils/date/date';
import { useFiles } from '@/utils/file/useFiles';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Spinner,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { FC, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import {
  type WorkOrderForm_WorkOrderFragment,
  useWorkOrderFormQuery,
} from './WorkOrderForm.generated';
import WorkOrderPriorityRadio from './WorkOrderPriorityRadio';
import { WorkOrderRecentlyUsedValues } from './WorkOrderRecentlyUsedValues';
import WorkOrderStartEndDatePicker from './WorkOrderStartEndDatePicker';
import WorkOrderStatusRadio from './WorkOrderStatusRadio';
import { WorkOrderStoppageReasonPicker } from './WorkOrderStoppageReasonPicker';
import WorkOrderCheckListField from './checkList/WorkOrderCheckListField';
import { CheckListFieldCardType } from './checkList/WorkOrderCheckListFieldCard';
import { CustomFieldFileValueType } from './customFields/CustomFieldFileValueType';
import CustomFieldItem from './customFields/CustomFieldItem';
import WorkOrderPartField, { WorkOrderPartCardType } from './parts/WorkOrderPartField';
const WorkOrderCheckListCustomFieldList = lazy(
  () => import('./checkList/WorkOrderCheckListCustomFieldList')
);

type WorkOrderFormDefaultDataType = {
  title?: string | undefined;
  assetId?: number | undefined;
  productId?: number | undefined;
  description?: string | undefined;
  dueDate?: string | undefined;
  stoppageStartAt?: string | undefined;
  stoppageEndAt?: string | undefined;
  stoppageReasonId: number | null;
  assignees?: IWorkOrderAssigneeInput[];
  status: WorkOrderStatus;
};

// 本来WorkOrder上にもfragmentを作りたくて再利用するなら、ReferenceDataとWorkOrderは共通のinterfaceを抽出する必要があるが、現状不要なのでTODO
gql`
  fragment WorkOrderForm_WorkOrder on WorkOrderInputReferenceData {
    title
    description
    assetId
    dueDate
    status
    priority
    groups {
      id
      name
    }
    assignees {
      id
      name
    }
    productId
    parts {
      quantity
      part {
        id
        name
        model
        unit
        stock
        currencyCode
        cost
      }
    }
    templateId
    stoppage {
      startAt
      endAt
    }
    stoppageReason {
      id
      name
    }
    checkLists {
      id
      template {
        id
        name
      }
    }
    customFieldTextValues {
      customFieldId
      value
    }
    customFieldIntValues {
      customFieldId
      value
    }
    customFieldFloatValues {
      customFieldId
      value
    }
    customFieldSelectValues {
      customFieldId
      optionId
      value
    }
    customFieldDateValues {
      customFieldId
      value
    }
    customFieldDatetimeValues {
      customFieldId
      value
    }
    customFieldUserValues {
      customFieldId
      user {
        id
        name
      }
    }
    customFieldFileValues {
      id
      customFieldId
      name
      contentType
      fileId
      src
    }
  }
`;

// Form描画に必要なデータを取得するためのクエリ（productsとかassetsの取得もApplicationContextからこちらに移行したい)
gql`
  query WorkOrderForm {
    recentlyUsedWorkOrderProducts(limit: 5) {
      ...WorkOrderRecentlyUsedValues_Product
    }

    recentlyUsedWorkOrderAssets(limit: 5) {
      ...WorkOrderRecentlyUsedValues_Asset
    }
  }
`;

export type WorkOrderFormProps = {
  // 操作
  submit: (workOrder: WorkOrderFormDataType) => void;
  pause?: (workOrder: WorkOrderFormDataType) => void;
  cancel: () => void;

  // ファイル関連
  customFieldFileValues?: CustomFieldFileValueType[];
  onAddCustomFieldFileValues?: (fileValues: CustomFieldFileValueType[]) => Promise<boolean>;
  onRemoveCustomFieldFileValue?: (fileId: string, customFieldId: number) => Promise<boolean>;
  onUpdateCustomFieldFileValue?: (
    fileId: string,
    params: {
      fileId: string;
      contentType: string;
      name: string;
      src: string;
    }
  ) => Promise<void>;
  // 更新の時に必要なもの。
  onUpdatePart?: (partId: number, quantity: number) => void;
  createCheckList?: (check: ICreateCheckListInput) => Promise<number>;
  deleteCheckList?: (checkListId: number) => void;
  // 表示の制御
  title?: string;
  submitButton?: string;
  usePauseButton?: boolean;
  displayStatus?: boolean;
  shouldConfirmStatusChange?: boolean;
  // フォームの表示の制御
  workOrder?: Partial<WorkOrderForm_WorkOrderFragment> & { id?: number };
  template?: IWorkOrderTemplateBase;
  checkListTemplate?: ICheckListTemplate;
};

const defaultValues: WorkOrderFormDefaultDataType = {
  title: '',
  assetId: undefined,
  productId: undefined,
  description: '',
  dueDate: undefined,
  stoppageStartAt: undefined,
  stoppageEndAt: undefined,
  status: 'open',
  stoppageReasonId: null,
};

const WorkOrderForm: FC<WorkOrderFormProps> = (props: WorkOrderFormProps) => {
  const { isMobile, isDesktop } = useScreenInfos();
  const { t, t_errors } = useTranslation(TASK);
  const { confirm } = useConfirmModal();
  const { getFileUploadUrls, cacheFileSrc } = useFiles();

  const {
    submit,
    pause,
    cancel,
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    onUpdateCustomFieldFileValue,
    onUpdatePart,
    workOrder,
    customFieldFileValues = [],
    createCheckList,
    deleteCheckList,
    title = t('actions.create-task'),
    submitButton = t('actions.register'),
    usePauseButton = false,
    displayStatus = false,
    shouldConfirmStatusChange = false,
    checkListTemplate,
  } = props;
  const { companySetting } = useApplicationContext();
  const { defaultWorkOrderTemplate } = useTemplateContext();

  const template = useMemo(() => {
    return (props.template || defaultWorkOrderTemplate) as IWorkOrderTemplateBase;
  }, [props.template, defaultWorkOrderTemplate]);

  const { customFields, fieldOrders } = template;

  const { selectProductItems } = useHierarchicalProducts();
  const { data: formData } = useWorkOrderFormQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [localCustomFieldFileValues, setLocalCustomFieldFileValues] =
    useState<CustomFieldFileValueType[]>(customFieldFileValues);

  const handleAddCustomFieldFileValues = useCallback(
    async (fileValues: CustomFieldFileValueType[]) => {
      if (!onAddCustomFieldFileValues || (await onAddCustomFieldFileValues(fileValues))) {
        setLocalCustomFieldFileValues((prev) => [...prev, ...fileValues]);
        fileValues.forEach((file) => cacheFileSrc(file.fileId, file.src));
      }
    },
    [cacheFileSrc, onAddCustomFieldFileValues]
  );

  const handleRemoveCustomFieldFileValue = async (fileId: string, customFieldId: number) => {
    if (
      !onRemoveCustomFieldFileValue ||
      (await onRemoveCustomFieldFileValue(fileId, customFieldId))
    ) {
      setLocalCustomFieldFileValues((values) => {
        const index = values.findIndex((value) => value.fileId === fileId);
        values.splice(index, 1);
        return [...values];
      });
    }
  };

  const handleUpdateCustomFieldFileValue = useCallback(
    async (
      fileId: string,
      params: {
        fileId: string;
        contentType: string;
        name: string;
        src: string;
      }
    ) => {
      await onUpdateCustomFieldFileValue?.(fileId, params);
      setLocalCustomFieldFileValues((prev) => {
        return prev.map((entry) =>
          entry.fileId === fileId ? { ...entry, ...params, key: entry.fileId } : entry
        );
      });
      cacheFileSrc(params.fileId, params.src);
    },
    [cacheFileSrc, onUpdateCustomFieldFileValue]
  );

  const initialParts = () => {
    if (!workOrder || !workOrder.parts) {
      return [];
    }

    return workOrder.parts.map((entry) => {
      if (!entry.part) throw new Error(t_errors('impossible'));
      return {
        partId: entry.part.id,
        name: entry.part.name,
        stock: entry.part.stock,
        quantity: entry.quantity,
        unit: entry.part.unit || undefined,
        cost: entry.part.cost || undefined,
      };
    });
  };

  const initialCheckLists = () => {
    if (!workOrder || !workOrder.checkLists) {
      return [];
    }

    return workOrder.checkLists.map((entry) => {
      return {
        id: entry.id,
        name: entry.template.name,
        templateId: entry.template.id,
      };
    });
  };

  const initialAssignees = () => {
    if (workOrder) {
      return toUserInputs(workOrder.assignees);
    }
    return [];
  };

  const initialGroups = () => {
    if (!workOrder || !workOrder.groups) {
      return [];
    }

    return workOrder.groups.map((group) => Number(group.id));
  };

  const [workOrderParts, setWorkOrderParts] = useState<WorkOrderPartCardType[]>(initialParts);
  const [checkLists, setCheckLists] = useState<CheckListFieldCardType[]>(initialCheckLists);
  const [status, setStatus] = useState<WorkOrderStatus>(workOrder?.status || 'open');
  const [assignees, setAssignees] = useState<IWorkOrderAssigneeInput[]>(initialAssignees);

  const [groupIds, setGroupIds] = useState<number[]>(initialGroups);

  const {
    customFieldTextValues,
    customFieldIntValues,
    customFieldFloatValues,
    customFieldSelectValues,
    customFieldDateValues,
    customFieldDatetimeValues,
    customFieldUserValues,
  } = convertCustomFieldValueToInputValue(workOrder || {});

  const [customFieldTextLocalValues, setCustomFieldTextLocalValues] =
    useState<IWorkOrderCustomFieldTextValueInput[]>(customFieldTextValues);
  const [customFieldIntLocalValues, setCustomFieldIntLocalValues] =
    useState<IWorkOrderCustomFieldIntValueInput[]>(customFieldIntValues);
  const [customFieldFloatLocalValues, setCustomFieldFloatLocalValues] =
    useState<IWorkOrderCustomFieldFloatValueInput[]>(customFieldFloatValues);
  const [customFieldSelectLocalValues, setCustomFieldSelectLocalValues] =
    useState<IWorkOrderCustomFieldSelectValueInput[]>(customFieldSelectValues);
  const [customFieldDatetimeLocalValues, setCustomFieldDatetimeLocalValues] =
    useState<IWorkOrderCustomFieldDatetimeValueInput[]>(customFieldDatetimeValues);
  const [customFieldDateLocalValues, setCustomFieldDateLocalValues] =
    useState<IWorkOrderCustomFieldDateValueInput[]>(customFieldDateValues);
  const [customFieldUserLocalValues, setCustomFieldUserLocalValues] =
    useState<IWorkOrderCustomFieldUserValueInput[]>(customFieldUserValues);

  const [selectedAssetId, setSelectedAssetId] = useState<number>();
  const [selectedProductId, setSelectedProductId] = useState<number>();

  const [priority, setPriority] = useState<WorkOrderPriority>(workOrder?.priority ?? 'none');

  const [checkListFormValue, setCheckListFormValue] = useState<CheckListFormValueType>(
    generateCheckListInitFormValue()
  );

  const onChangeWorkOrderPart = useCallback((parts: WorkOrderPartCardType[]) => {
    setWorkOrderParts(parts);
  }, []);

  const onChangeCheckLists = useCallback((checkLists: CheckListFieldCardType[]) => {
    setCheckLists(checkLists);
  }, []);

  const onChangeCheckListFormValue = (checkListFormValue: CheckListFormValueType) => {
    setCheckListFormValue(checkListFormValue);
  };

  const methods = useForm<WorkOrderFormDefaultDataType>({
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    register,
    getValues,
  } = methods;

  // FIXME: delete useEffect
  useEffect(() => {
    // 編集の場合
    if (workOrder) {
      const {
        title,
        assetId,
        description,
        stoppage,
        dueDate,
        priority,
        productId,
        stoppageReason,
      } = workOrder;
      setValue('title', title || '');
      setValue('assetId', assetId ?? undefined);
      setValue('productId', productId ?? undefined);
      setValue('description', description || '');
      setValue(
        'stoppageStartAt',
        stoppage ? formatDateToYYYYMMDDHHmmForInput(stoppage.startAt) : undefined
      );
      setValue(
        'stoppageEndAt',
        stoppage ? formatDateToYYYYMMDDHHmmForInput(stoppage.endAt) : undefined
      );
      setValue('dueDate', dueDate ? formatDateToYYYYMMDDForInput(dueDate) : undefined);
      setValue('stoppageReasonId', stoppageReason?.id ?? null);
      setSelectedAssetId(assetId ?? undefined);
      setSelectedProductId(productId ?? undefined);
      if (priority) setPriority(priority);
    }
  }, [setValue, workOrder]);

  const createAndValidateWorkOrderFromValue = (values: FieldValues): WorkOrderFormDataType => {
    const {
      title,
      assetId: assetIdString,
      productId: productIdString,
      description,
      stoppageStartAt,
      stoppageEndAt,
      dueDate,
      stoppageReasonId,
    } = values;

    if (isFromDateAfterToDate(stoppageStartAt, stoppageEndAt)) {
      setError('stoppageEndAt', {
        type: 'custom',
        message: t_errors('invalid-start-and-end-time'),
      });
    }

    const assetId = assetIdString ? Number(assetIdString) : undefined;
    const productId = productIdString ? Number(productIdString) : undefined;

    const localOrderCustomFieldFileValues = localCustomFieldFileValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        fileId: entry.fileId,
        contentType: entry.contentType,
        name: entry.name,
      };
    });

    const localCheckLists = checkLists.map((checkList) => {
      return { templateId: checkList.templateId };
    });

    const customFieldStampValues = checkListFormValue.customFieldStampValues.map(
      (customFieldStampValue) => {
        return {
          customFieldId: customFieldStampValue.customFieldId,
          stampedById: customFieldStampValue.stampedBy.id,
          stampedAt: customFieldStampValue.stampedAt,
        };
      }
    );

    const workOrder: WorkOrderFormDataType = {
      title,
      assetId,
      productId,
      description,
      status,
      dueDate: dueDate ? dueDate : null,
      assignees,
      groupIds,
      priority,
      customFieldTextValues: customFieldTextLocalValues,
      customFieldIntValues: customFieldIntLocalValues,
      customFieldFloatValues: customFieldFloatLocalValues,
      customFieldSelectValues: customFieldSelectLocalValues,
      customFieldDatetimeValues: customFieldDatetimeLocalValues,
      customFieldDateValues: customFieldDateLocalValues,
      customFieldUserValues: customFieldUserLocalValues,
      stoppage: {
        startAt: stoppageStartAt ? new Date(stoppageStartAt) : undefined,
        endAt: stoppageEndAt ? new Date(stoppageEndAt) : undefined,
      },
      stoppageReasonId: stoppageReasonId ? parseInt(stoppageReasonId) : undefined,
      parts: workOrderParts.map((part) => {
        return { partId: part.partId, quantity: part.quantity };
      }),
      customFieldFileValues: localOrderCustomFieldFileValues,
      checkLists: checkListTemplate
        ? [{ templateId: checkListTemplate.id, ...checkListFormValue, customFieldStampValues }]
        : localCheckLists,
      templateId: template.id,
    };

    return workOrder;
  };

  const onSubmit = (values: FieldValues) => {
    const workOrder = createAndValidateWorkOrderFromValue(values);
    submit(workOrder);
  };

  const onChangeAsset = (value?: number) => {
    setValue('assetId', value);
    setSelectedAssetId(value);
    if (value) {
      clearErrors('assetId');
    }
  };

  const onChangeProduct = (value?: number | string) => {
    setValue('productId', Number(value));
    setSelectedProductId(Number(value));
    if (value) {
      clearErrors('productId');
    }
  };

  const onChangeWorkOrderStatus = async (nextStatus: WorkOrderStatus) => {
    if (
      shouldConfirmStatusChange &&
      status === WorkOrderDoneStatus &&
      nextStatus !== WorkOrderDoneStatus
    ) {
      const result = await confirm(
        t('confirmation.task.revert-done-task'),
        undefined,
        t('actions.mark-as-incomplete'),
        {
          colorScheme: 'primary',
        },
        t('actions.cancel')
      );
      if (!result) {
        return;
      }
    }
    setStatus(nextStatus);
  };

  let displayCustomFields: IWorkOrderCustomField[] = [];

  if (customFields) {
    displayCustomFields = customFields.filter((customField) => {
      const { inputTimings } = customField;
      const hasNoInputTimings = inputTimings.length === 0;
      const includesInitialOrWorkOrderStatus =
        inputTimings.includes('initial') ||
        (workOrder && workOrder.status && inputTimings.includes(workOrder.status));

      return hasNoInputTimings || includesInitialOrWorkOrderStatus;
    });
  }

  const assigneesInputHandler = (values: IWorkOrderAssigneeInput[]) => {
    setAssignees(values);
  };

  const groupsInputHandler = (values: number[]) => {
    setGroupIds(values);
  };

  const handleCancel = () => {
    cancel();
  };

  const handlePause = () => {
    if (!pause) throw new Error('pause is not defined');

    const values = getValues();
    const workOrder = createAndValidateWorkOrderFromValue(values);
    pause(workOrder);
  };

  if (!companySetting) return null;
  const { accessCheckList, accessProduct } = companySetting;
  return (
    <Flex direction='column' bg='neutral.50' height='inherit'>
      <PageTitle
        title={title}
        titleSize='sm'
        minH={{ base: 'auto', md: '52px' }}
        maxH={{ base: 'auto', md: '52px' }}
        position='sticky'
        top={0}
        zIndex={10}
        leftChild={
          isMobile && (
            <IconButton
              onClick={() => cancel()}
              aria-label='Back'
              size='md'
              variant='ghost'
              icon={<MdClose />}
            />
          )
        }
      >
        {isMobile && (
          <Flex gap={2}>
            {usePauseButton && (
              <Button color='gray.500' onClick={handlePause} width='60px' variant='ghost' size='sm'>
                {t('actions.pause')}
              </Button>
            )}

            <Button
              colorScheme='primary'
              onClick={handleSubmit(onSubmit)}
              width='60px'
              variant='ghost'
              size='sm'
            >
              {submitButton}
            </Button>
          </Flex>
        )}
      </PageTitle>

      <Box as='form' onSubmit={handleSubmit(onSubmit)} flex='1' overflowY='scroll'>
        {displayStatus && (
          <Box alignItems='flex-start' my={4} mx={2}>
            <WorkOrderStatusRadio status={status} updateWorkOrderStatus={onChangeWorkOrderStatus} />
          </Box>
        )}

        {[...fieldOrders]
          .sort((a, b) => a.order - b.order)
          .map((fieldOrder) => {
            return (
              <Box key={fieldOrder.id} width='100%'>
                {fieldOrder.type === 'title' && (
                  <FormControl
                    isInvalid={!!errors.title}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel htmlFor='title'>{t('title')}</FormLabel>
                    <Input id='title' {...register('title')} />
                    <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
                  </FormControl>
                )}

                {fieldOrder.type === 'description' && (
                  <FormControl
                    isInvalid={!!errors.description}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel>{t('description')}</FormLabel>
                    <Textarea id='description' {...register('description')} />
                    <FormErrorMessage>
                      {errors.description && errors.description.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                {fieldOrder.type === 'asset' && (
                  <FormControl
                    isInvalid={!selectedAssetId && !!errors.assetId}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                    data-testid='work-order-form-asset'
                  >
                    <FormLabel>{t('pages.asset')}</FormLabel>

                    <SuspenseWithSpinner>
                      <AssetMultiLayerSelect value={selectedAssetId} onChange={onChangeAsset} />
                    </SuspenseWithSpinner>
                    <FormErrorMessage>{errors.assetId && errors.assetId.message}</FormErrorMessage>
                    <VStack mt={2} alignItems='start' gap={0}>
                      <Heading size='xs'>{t('form.recently-used')}</Heading>

                      <Flex minH='40px'>
                        {!formData ? (
                          <Spinner color='primary.500' size='sm' alignSelf='center' />
                        ) : (
                          <WorkOrderRecentlyUsedValues
                            items={formData.recentlyUsedWorkOrderAssets}
                            onClick={(item) => onChangeAsset(item.id)}
                          />
                        )}
                      </Flex>
                    </VStack>
                  </FormControl>
                )}

                {fieldOrder.type === 'due' && (
                  <FormControl
                    isInvalid={!!errors.dueDate}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel>{t('date.due-date')}</FormLabel>
                    <Input id='due-date-at' type='date' {...register('dueDate')} />
                    <FormErrorMessage>
                      {errors.dueDate &&
                        (errors.dueDate.type === 'required'
                          ? t_errors('item-is-not-defined', { item: t('date.due-date') })
                          : errors.dueDate.message)}
                    </FormErrorMessage>
                  </FormControl>
                )}

                {accessProduct && fieldOrder.type === 'product' && (
                  <FormControl
                    isInvalid={!selectedProductId && !!errors.productId}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                    data-testid='work-order-form-product'
                  >
                    <FormLabel>{t('pages.product')}</FormLabel>
                    <MultipleLayerSelect
                      value={selectedProductId}
                      options={selectProductItems}
                      onChange={(value) => onChangeProduct(value)}
                    />

                    <FormErrorMessage>
                      {errors.productId && errors.productId.message}
                    </FormErrorMessage>

                    <VStack mt={2} alignItems='start' gap={0}>
                      <Heading size='xs'>{t('form.recently-used')}</Heading>
                      <Flex minH='40px'>
                        {!formData ? (
                          <Spinner color='primary.500' size='sm' alignSelf='center' />
                        ) : (
                          <WorkOrderRecentlyUsedValues
                            items={formData.recentlyUsedWorkOrderProducts}
                            onClick={(item) => onChangeProduct(item.id)}
                          />
                        )}
                      </Flex>
                    </VStack>
                  </FormControl>
                )}

                {fieldOrder.type === 'assignee' && (
                  <FormControl
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                    data-testid='work-order-form-assignee'
                  >
                    <FormLabel>{t('assignee')}</FormLabel>
                    <UserSelectInput
                      defaultUserValues={assignees}
                      onChange={assigneesInputHandler}
                    />
                  </FormControl>
                )}

                {fieldOrder.type === 'group' && companySetting?.accessGroup && (
                  <FormControl
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel>{t('group')}</FormLabel>
                    <GroupSelectInput defaultGroupValues={groupIds} onChange={groupsInputHandler} />
                  </FormControl>
                )}

                {fieldOrder.type === 'priority' && (
                  <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
                    <FormControl data-testid='work-order-form-priority'>
                      <FormLabel>{t('priority.title')}</FormLabel>
                      <WorkOrderPriorityRadio
                        priority={priority}
                        updateWorkOrderPriority={setPriority}
                      />
                    </FormControl>
                  </Box>
                )}

                {fieldOrder.type === 'stoppage' && (
                  <FormProvider {...methods}>
                    <WorkOrderStartEndDatePicker />
                  </FormProvider>
                )}

                {fieldOrder.type === 'stoppageReason' && (
                  <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
                    <FormControl data-testid='work-order-form-stoppage-reason'>
                      <FormLabel>{t('stoppage-reason.title')}</FormLabel>
                      <Controller
                        control={methods.control}
                        name='stoppageReasonId'
                        render={({ field }) => (
                          <WorkOrderStoppageReasonPicker
                            assetId={selectedAssetId}
                            additionalItem={workOrder?.stoppageReason}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                )}

                {fieldOrder.type === 'checkList' && accessCheckList && (
                  <>
                    {checkListTemplate ? (
                      <SuspenseWithSpinner>
                        <WorkOrderCheckListCustomFieldList
                          isEdit
                          name={checkListTemplate.name}
                          description={checkListTemplate.description}
                          items={checkListTemplate.items}
                          checkListFormValue={checkListFormValue}
                          onChangeCheckListValue={onChangeCheckListFormValue}
                        />
                      </SuspenseWithSpinner>
                    ) : (
                      <FormControl
                        bg='neutral.0'
                        borderRadius='md'
                        px={4}
                        py={2}
                        mx={2}
                        my={4}
                        width='auto'
                      >
                        <FormLabel fontWeight='bold'>{t('pages.check-list')}</FormLabel>
                        <WorkOrderCheckListField
                          workOrderId={workOrder ? workOrder.id : undefined}
                          isEdit
                          checkLists={checkLists}
                          onChangeCheckLists={onChangeCheckLists}
                          createCheckList={createCheckList}
                          deleteCheckList={deleteCheckList}
                        />
                      </FormControl>
                    )}
                  </>
                )}

                {fieldOrder.type === 'part' && (
                  <FormControl
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <WorkOrderPartField
                      workOrderId={workOrder ? workOrder.id : undefined}
                      assetId={selectedAssetId}
                      workOrderParts={workOrderParts}
                      onChangeWorkOrderPart={onChangeWorkOrderPart}
                      onUpdatePart={onUpdatePart}
                    />
                  </FormControl>
                )}

                {fieldOrder.type === 'customField' &&
                  (() => {
                    const displayCustomField = displayCustomFields.find(
                      (entry) => entry.id === fieldOrder.customFieldId
                    );
                    return (
                      <>
                        {displayCustomField && (
                          <CustomFieldItem
                            assetId={selectedAssetId}
                            customField={displayCustomField}
                            customFieldTextValues={customFieldTextLocalValues}
                            customFieldIntValues={customFieldIntLocalValues}
                            customFieldFloatValues={customFieldFloatLocalValues}
                            customFieldSelectValues={customFieldSelectLocalValues}
                            customFieldDateValues={customFieldDateLocalValues}
                            customFieldDatetimeValues={customFieldDatetimeLocalValues}
                            customFieldUserValues={customFieldUserLocalValues}
                            customFieldFileValues={localCustomFieldFileValues}
                            changeCustomFieldTextValues={setCustomFieldTextLocalValues}
                            changeCustomFieldIntValues={setCustomFieldIntLocalValues}
                            changeCustomFieldFloatValues={setCustomFieldFloatLocalValues}
                            changeCustomFieldSelectValues={setCustomFieldSelectLocalValues}
                            changeCustomFieldDateValues={setCustomFieldDateLocalValues}
                            changeCustomFieldDatetimeValues={setCustomFieldDatetimeLocalValues}
                            changeCustomFieldUserValues={setCustomFieldUserLocalValues}
                            onAddCustomFieldFileValues={handleAddCustomFieldFileValues}
                            onRemoveCustomFieldFileValue={handleRemoveCustomFieldFileValue}
                            getFileUploadUrls={getFileUploadUrls}
                            onUpdateCustomFieldFileValue={handleUpdateCustomFieldFileValue}
                          />
                        )}
                      </>
                    );
                  })()}
              </Box>
            );
          })}
      </Box>

      {isDesktop && (
        <Flex
          justifyContent='flex-end'
          width='full'
          left='0'
          py='2'
          px='4'
          backgroundColor='neutral.0'
          borderTop='1px'
          borderColor='neutral.200'
          zIndex={101}
          gap={2}
          height={{ base: 'auto', md: '54px' }}
        >
          <Button colorScheme='gray' width='auto' onClick={handleCancel}>
            {t('actions.cancel')}
          </Button>
          {usePauseButton && (
            <Button variant='outline' colorScheme='primary' width='auto' onClick={handlePause}>
              {t('actions.pause')}
            </Button>
          )}

          <Button
            colorScheme='primary'
            isLoading={isSubmitting}
            type='submit'
            width='100px'
            onClick={handleSubmit(onSubmit)}
          >
            {submitButton}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default WorkOrderForm;
