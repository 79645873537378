import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import { uniqBy } from '@/utils/array/array';
import { gql } from '@apollo/client';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  type GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery,
  useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery,
} from './WorkOrderStoppageReasonPicker.generated';

type Props = {
  assetId: number | undefined;
  value: number | null;
  // すでに削除ずみのreasonが選択されている場合に表示・選択肢に追加するため
  additionalItem:
    | NonNullable<
        GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery['asset']['stoppageReasons']
      >[number]
    | undefined
    | null;
  onChange: (value: number | null) => void;
};

gql`
  query GetAssetStoppageReasonForWorkOrderStoppageReasonPicker($assetId: Int!) {
    asset(id: $assetId) {
      stoppageReasons {
        id
        name
      }
    }
  }
`;

export const WorkOrderStoppageReasonPicker = ({
  assetId,
  value,
  additionalItem,
  onChange,
}: Props) => {
  const { data, loading } = useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery({
    skip: !assetId,
    variables: { assetId: assetId! },
  });
  const { t } = useTranslation();

  const reasons = data?.asset.stoppageReasons
    ? uniqBy(
        [...(additionalItem ? [additionalItem] : []), ...data.asset.stoppageReasons],
        (r) => r.id
      )
    : [];

  if (additionalItem?.id !== value) {
    if (loading) {
      return <Spinner />;
    }

    if (!assetId) {
      return (
        <Box p={2}>
          <Text color='neutral.400'>{t('stoppage-reason.select-asset-first')}</Text>
        </Box>
      );
    }
  }

  return (
    <MultipleLayerSelect
      value={value ?? undefined}
      options={reasons.map((reason) => ({ id: reason.id, label: reason.name }))}
      onChange={(value) => onChange((value ?? null) as number | null)}
      isClearable
      placeholder={t('stoppage-reason.select')}
    />
  );
};
